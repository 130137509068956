<template>
  <main class="about-page">
    <a class="whatsappWrapper" href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!"><img src="../assets/img/WhatsApp.svg"/></a>
    <div class="container">
      <!-- /.main-hero -->
 <img
        src="../assets/img/final_3d_images/low/img023.jpg"
        alt="Sports Overview"
        class="about-page-img img-cover"
      />
      <!-- Right Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1">
            <div class="content">
              <h2>Lawn Tennis</h2>
              <p>
                Tennis can be a great workout and lots of fun. Playing tennis
                has many health benefits including :
                <br />Increasing aerobic capacities <br />Lowering resting heart
                rate and blood pressure <br />Improving metabolic function
                <br />Increasing bone density <br />Lowering body fat
                <br />Improving muscle tone, strength and flexibility
                <br />Increasing reaction times.
              </p>
            </div>
          </div>
          <!-- /.block-1 -->

          <div class="block-2">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img027.jpg"
                alt="Lawn Tennis"
                class="img-cover"
              />
            </div>
          </div>

          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Left Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1" style="margin: 0px; width: 43%">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img035.jpg"
                alt="Sand Volleyball"
                class="img-cover"
              />
            </div>
          </div>
          <!-- /.block-1 -->

          <div
            style="margin-left: 100px; weight: calc(43% - 200px)"
            class="block-2"
          >
            <div class="content">
              <h2>Sand Volleyball</h2>
              <p>
                Playing sand volleyball is not only challenging and enjoyable,
                but it’s also good for your health. The resistance that sand
                provides not only forces you to work harder, but it can also
                help promote a healthy cardiovascular system. The sand is a
                constant distraction, requiring continual feats of acrobatics
                and balance. Combine that with attempting to keep your attention
                focused on the ball, it makes for an excellent workout for the
                brain as well as the body. It’s true that sand volleyball is a
                super effective way to burn calories. A study from Harvard
                Medical School showed that an hour-long game of sand volleyball
                could burn up to almost 500 calories!
              </p>
            </div>
          </div>
          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Right Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1">
            <div class="content">
              <h2>Mini Football</h2>
              <p>
                Football is world’s most popular participation sport. The
                actions performed in football are a result of multi-joint,
                multi-muscle movements, with muscles coordinating with each
                other to produce efficient movement, multi-directional forces
                and a stable structure to produce their movements. Football
                involves running, sprinting and drills that require generous
                energy expenditure and engage the cardiovascular system.
                Regularly participating in aerobic exercise such as football
                offers benefits such as increased longevity, as well as reduced
                susceptibility to heart disease, osteoporosis, diabetes,
                Improving heart health and blood pressure, Building strength,
                stamina and speed, Reducing body fat and improving concentration
                & coordination.
              </p>
            </div>
          </div>
          <!-- /.block-1 -->

          <div class="block-2">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img085.jpg"
                alt="Mini Football"
                class="img-cover"
              />
            </div>
          </div>

          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Left Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1" style="margin: 0px; width: 43%">
            <div class="about-image">
              <img
                src="../assets/overscans/cricket.jpg"
                alt="Box Cricket"
                class="img-cover"
              />
            </div>
          </div>
          <!-- /.block-1 -->

          <div
            style="margin-left: 100px; weight: calc(43% - 200px)"
            class="block-2"
          >
            <div class="content">
              <h2>Box Cricket</h2>
              <p>
                Cricket is a Vogue sport in India. We found it in every street
                of India. Box Cricket is a mini version of our beloved Cricket.
                It’s as much fun playing cricket in a box as in a stadium. There
                are new experiences to open yourself up to in our open space.
                Playing Cricket has many health benefits including ; Endurance
                &amp; stamina, Balance &amp; coordination, Physical fitness and
                Improving hand-eye coordination.
              </p>
            </div>
          </div>
          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Full Width Image -->
     

      <!-- /.info -->
    </div>
    <!-- /.container -->
  </main>
  <!-- /main -->
</template>

<script>
export default {
  name: "",
};
</script>

<style>
</style>